<template>
  <div class="task container">

    <v-header v-if="!hideHeader"></v-header>

    <div class="scroll-container" ref="scrollContainer">
      <div class="task-title">
        <img src="https://cdn.001ppt.cn/h5/assets/images/task-img.png"/>
        <div style="flex-grow: 1;display: flex;align-items: flex-end;justify-content: space-between">
          <div class="content">严值任务</div>
          <div class="task-route">
            <div class="task-route-item" @click="$router.push('/record?tab=TASK')">奖励记录
              <van-icon name="arrow"></van-icon>
            </div>
            <!--            <div class="task-route-item" @click="$router.push('/rank')">奖励榜 ></div>-->
          </div>
        </div>
      </div>
      <div class="focus-tip" @click="$refs.gzhDialog.show()">想第一时间收到奖励提醒？</div>
      <div class="task-nav">
        <div class="task-nav-item" @click="goTaskType(0)">推荐官任务</div>
        <div class="task-nav-item" @click="goTaskType(1)">每日任务</div>
        <div class="task-nav-item" @click="goTaskType(2)">新人任务</div>
        <div class="task-nav-item" @click="goTaskType(3)">创作者任务</div>
      </div>
      <div class="task-nav-holder"></div>

      <div class="task-content" v-if="Object.keys(tasks).length">

        <!--推荐官任务-->
        <template v-if="tasks['邀请用户首次登录成功']">
          <span class="task-type">推荐官任务</span>
          <div class="task-list full">
            <div class="task-item" v-if="tasks['邀请用户首次登录成功']">
              <span>{{ tasks['邀请用户首次登录成功'].name }}</span>
              <span class="task-reward">+{{ tasks['邀请用户首次登录成功'].taskYanzhi }}严值</span>
              <span class="desc">{{ tasks['邀请用户首次登录成功'].desc }}</span>
              <div class="image-group">
                <i class="iconfont icon-go" @click="showModal"></i>
              </div>
            </div>
            <div class="task-item" v-if="tasks['邀请5个用户首次登录成功']">
              <span>{{ tasks['邀请5个用户首次登录成功'].name }}</span>
              <span class="task-reward"
                    v-if="tasks['邀请5个用户首次登录成功'].awardVipRightId === '1'">+送试用会员</span>
              <span class="task-reward"
                    v-else-if="tasks['邀请5个用户首次登录成功'].awardVipRightId === '2'">+送入门会员</span>
              <span class="task-reward"
                    v-else-if="tasks['邀请5个用户首次登录成功'].awardVipRightId === '4'">+送超级VIP会员</span>
              <span class="desc">{{ tasks['邀请5个用户首次登录成功'].desc }}</span>
              <div class="image-group">
                <i class="iconfont icon-go" @click="showModal"></i>
              </div>
            </div>
            <div class="task-item" v-if="tasks['邀请好友加入超级VIP会员']">
              <span>{{ tasks['邀请好友加入超级VIP会员'].name }}</span>
              <span class="task-reward">+{{ tasks['邀请好友加入超级VIP会员'].profitYanzhi }}收益严值(可提现)</span>
              <span class="desc">{{ tasks['邀请好友加入超级VIP会员'].desc }}</span>
              <div class="image-group">
                <i class="iconfont icon-go" @click="()=>this.$refs.inviteCodeModel.toggleDialog()"></i>
              </div>
            </div>
          </div>
        </template>

        <!--每日任务-->
        <template
          v-if="tasks['每天浏览10篇内容']  || tasks['每天点赞10篇内容'] || tasks['每天收藏10篇内容'] || tasks['每日首次登录']">
          <span class="task-type">每日任务</span>
          <div class="task-list grid">
            <div class="task-item" v-if="tasks['每日首次登录']">
              <span>{{ tasks['每日首次登录'].name }}</span>
              <span class="task-reward">+{{ tasks['每日首次登录'].taskYanzhi }}严值</span>
              <div class='image-group'>
                <i class="iconfont icon-go" v-if="!tasks['每日首次登录'].hasFinish"
                   @click="$router.push('/home')"></i>
                <i class="iconfont icon-complete" v-else></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['每天浏览10篇内容']">
              <span>{{ tasks['每天浏览10篇内容'].name }}</span>
              <span class="task-reward">+{{ tasks['每天浏览10篇内容'].taskYanzhi }}严值</span>
              <div class="image-group">
                <i class="iconfont icon-complete" v-if="tasks['每天浏览10篇内容'].hasFinish"></i>
                <i class="iconfont icon-go" v-else></i>
              </div>
            </div>
            <div class="task-item" v-if="tasks['每天点赞10篇内容']">
              <span>{{ tasks['每天点赞10篇内容'].name }}</span>
              <span class="task-reward">+{{ tasks['每天点赞10篇内容'].taskYanzhi }}严值</span>
              <div class='image-group'>
                <i class="iconfont icon-go" v-if="!tasks['每天点赞10篇内容'].hasFinish"
                   @click="$router.push('/home')"></i>
                <i class="iconfont icon-complete" v-else></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['每天收藏10篇内容']">
              <span>{{ tasks['每天收藏10篇内容'].name }}</span>
              <span class="task-reward">+{{ tasks['每天收藏10篇内容'].taskYanzhi }}严值</span>
              <div class='image-group'>
                <i class="iconfont icon-go" v-if="!tasks['每天收藏10篇内容'].hasFinish"
                   @click="$router.push('/home')"></i>
                <i class="iconfont icon-complete" v-else></i>
              </div>
            </div>


          </div>
        </template>

        <!--新人任务-->
        <template v-if="tasks['首次登录成功']  || tasks['完善所有个人资料']">
          <span class="task-type">新人任务</span>
          <div class="task-list grid">
            <div class="task-item" v-if="tasks['首次登录成功']">
              <span>{{ tasks['首次登录成功'].name }}</span>
              <span class="task-reward">+{{ tasks['首次登录成功'].taskYanzhi }}严值</span>
              <div class="image-group">

                <i class="iconfont icon-complete" v-if="!tasks['首次登录成功'].hasFinish"></i>
                <i class="iconfont icon-go" v-else style="opacity: .3;"></i>
              </div>
            </div>
            <div class="task-item" v-if="tasks['完善所有个人资料']">
              <span>{{ tasks['完善所有个人资料'].name }}</span>
              <span class="task-reward">+{{ tasks['完善所有个人资料'].taskYanzhi }}严值</span>
              <div class='image-group'>
                <i class="iconfont icon-go" style="opacity: .3" v-if="tasks['完善所有个人资料'].hasFinish"></i>
                <i class="iconfont icon-go" v-else @click="$router.push('/editInfo')"></i>
              </div>
            </div>

          </div>
        </template>


        <!--创作者任务-->
        <template
          v-if="tasks['上传方案'] || tasks['获得推荐'] || tasks['获得收藏'] || tasks['首单下载收益'] || tasks['日常下载收益']">

          <span class="task-type">创作者任务</span>
          <div class="task-list full">
            <div class="task-item" v-if="tasks['上传方案']">
              <span>{{ tasks['上传方案'].name }}</span>
              <span class="task-reward">+{{ tasks['上传方案'].taskYanzhi }}严值</span>
              <span class="desc">{{ tasks['上传方案'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['获得推荐']">
              <span>{{ tasks['获得推荐'].name }}量</span>
              <span class="task-reward">+{{ tasks['获得推荐'].taskYanzhi }}严值</span>
              <span class="desc">{{ tasks['获得推荐'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>
            <div class="task-item" v-if="tasks['获得收藏']">
              <span>{{ tasks['获得收藏'].name }}量</span>
              <span class="task-reward">+{{ tasks['获得收藏'].taskYanzhi }}严值</span>
              <span class="desc">{{ tasks['获得收藏'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['首单下载收益']">
              <span>{{ tasks['首单下载收益'].name }}</span>
              <span class="task-reward">+{{ 100 }}%收益严值(可提现)</span>
              <span class="desc">{{ tasks['首单下载收益'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['日常下载收益']">
              <span>{{ tasks['日常下载收益'].name }}</span>
              <span class="task-reward">+{{ 50 }}%收益严值(可提现)</span>
              <span class="desc">{{ tasks['日常下载收益'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['方案周审核通过次数达标']">
              <span>{{ tasks['方案周审核通过次数达标'].name }}</span>
              <span class="task-reward">+{{ tasks['方案周审核通过次数达标'].profitYanzhi }}收益严值(可提现)</span>
              <span class="desc">{{ tasks['方案周审核通过次数达标'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>

            <div class="task-item" v-if="tasks['方案月审核通过次数达标']">
              <span>{{ tasks['方案月审核通过次数达标'].name }}</span>
              <span class="task-reward">+{{ tasks['方案月审核通过次数达标'].profitYanzhi }}收益严值(可提现)</span>
              <span class="desc">{{ tasks['方案月审核通过次数达标'].desc }}</span>
              <div class="image-group">
                <span class="desc">前往电脑端完成</span>
                <i class="iconfont icon-go" style="opacity: .3;"></i>
              </div>
            </div>
          </div>
          <div style="font-size: 12px;text-align: center;padding-top: 16px">用户等级越高奖励越多</div>
          <div @click="$router.push('/userLevel')"
               style="color: #02A9F1;font-size: 12px;text-align: center;padding-top: 4px">规则详见
          </div>
          <div style="height: 64px"></div>
        </template>
      </div>
    </div>
    <!--严值-->
    <recommend-dialog ref="model" @share="$refs.commonShareSheet.show()"/>
    <invite-code-dialog ref="inviteCodeModel"/>
    <common-share-sheet ref="commonShareSheet" @share="share"/>

    <gzh-dialog :type="1" ref="gzhDialog"/>
  </div>
</template>

<script>
import {user} from '@/api'
import model from '@/components/model/model'
import {mapGetters} from 'vuex'
import {userV2Api} from "../../api/v2/userV2Api";
import GzhDialog from '@/components/GzhDialog'
import RecommendDialog from "../../components/biz/RecommendDialog";
import {taskV2Api} from "../../api/v2/taskV2Api";
import InviteCodeDialog from "../../components/biz/inviteCodeDialog";
import CommonShareSheet from "../../components/commonShareSheet";
import {copyToClipBoard} from "../../config/util";

export default {
  props: ['hideHeader'],
  name: "task",
  components: {CommonShareSheet, InviteCodeDialog, RecommendDialog, model, GzhDialog},
  computed: {...mapGetters(['userInfo'])},
  data() {
    return {
      tasks: {},
      inviteCode: ''
    }
  },
  mounted() {
    this.getAllTask();
    this.$refs.scrollContainer.addEventListener('scroll', this.onScroll);
    this.onScroll()
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    goTaskType(i) {
      const taskTypes = document.getElementsByClassName('task-type');
      const scrollTop = taskTypes[i].offsetTop - 119;
      this.$refs.scrollContainer.scrollTo({top: scrollTop, behavior: 'smooth'})
    },
    async onScroll() {
      const scrollTop = this.$refs.scrollContainer.scrollTop;
      if (scrollTop > 120 && !this.$refs.scrollContainer.classList.contains('task-nav-fixed')) {
        this.$refs.scrollContainer.classList.add('task-nav-fixed')
      } else if (scrollTop <= 120 && this.$refs.scrollContainer.classList.contains('task-nav-fixed')) {
        this.$refs.scrollContainer.classList.remove('task-nav-fixed');
      }

      const taskTypes = document.getElementsByClassName('task-type');
      const taskNavItems = document.getElementsByClassName('task-nav-item');
      let activeIdx = 0;
      for (let i = 0; i < taskTypes.length; i++) {
        const taskType = taskTypes[i]
        const delta = taskType.offsetTop - scrollTop;
        if (delta < 120) {
          activeIdx = i
        }
      }

      for (let i = 0; i < taskNavItems.length; i++) {
        const item = taskNavItems[i];
        if (i === activeIdx && !item.classList.contains('active')) {
          item.classList.add('active');
        } else if (i !== activeIdx && item.classList.contains('active')) {
          item.classList.remove('active');
        }
      }
    },


    async getAllTask() {
      const res = await taskV2Api.findAll()
      const data = {}
      for (const row of res) {
        data[row.name] = row
      }

      this.tasks = data;

      if (this.$route.query.inviteCodeVisible === 'true') {
        setTimeout(() => {
          this.$refs.inviteCodeModel.toggleDialog()
        }, 50);
      }
    },

    showModal() {
      this.$refs['model'].toggleDialog();
    },

    //复制s
    share(e) {
      if (!Object.keys(this.userInfo).length) {
        return this.$store.dispatch('showLoginDialog');
      }

      if (e === 'sharePost') {
        return this.$router.push('/home/sharePost')
      } else {
        const url = 'https://www.001ppt.com?shareUserId=' + this.userInfo.id
        const str = localStorage.getItem('case') + '：' + url;
        try {
          copyToClipBoard(str)
          this.$toast.success('已复制链接，快去分享吧~');
        } catch (e) {
          this.$toast.error('复制失败');
        }
      }

      this.$refs.commonShareSheet.hide()
    },

  }
}
</script>

<style scoped lang="less">
.scroll-container {
  padding: 15px 28px;
  background-color: var(--page-color);
}

.task-title {
  background-color: var(--text-color);
  height: 62px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 14px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-title img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  flex-shrink: 0;
}

.task-title .content {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-left: 9px;
}

.task-route {
  display: flex;
  align-items: center;
  opacity: .3;
  font-size: 12px;
  color: #fff;
}

.task-route-item {
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.task-content {
}

.task-type {
  margin-top: 16px;
  display: flex;
  font-weight: bold;
  margin-bottom: 16px;
}

.task-list {
  display: flex;
  flex-wrap: wrap;
}

.task-list .task-item {
  box-sizing: border-box;
  padding: 12px 9px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(21, 29, 54, 0.05);
}

.grid .task-item {
  width: calc((100vw - 56px - 18px) / 2);
}

.full .task-item {
  width: calc(100vw - 28px * 2);
}

.full .task-item .desc {
  font-size: 12px;
  opacity: 0.3;
}

.full .image-group {
  margin-top: 5px;
}

.full .image-group span {
  margin-right: 5px;
}

.full .task-item:nth-child(n+2) {
  margin-top: 17px;
}


.task-list .task-item:active {
  background-color: var(--page-color);
}

.grid .task-item:nth-child(2n+2) {
  margin-left: 18px;
}

.grid .task-item:nth-child(n+3) {
  margin-top: 17px;
}

.task-item .task-reward {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0;
}

.task-item .image-group {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.task-list .image-group i {
  font-size: 20px;
  color: var(--text-color);
}

.task-list .image-group i.icon-complete {
  opacity: .3;
}

.invite_code {
  color: #ff0000;
  padding-top: 24px;
  font-size: 24px;
}

.focus-tip {
  color: var(--grey-color);
  font-size: 12px;
  text-align: right;
  padding-top: 18px;
}

.task-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  transition: background .3s;
}

.task-nav-holder {
  display: none;
  width: 100%;
  padding: 16px 0;
  height: 14px;
}

.task-nav-item {
  font-size: 14px;
  padding: 16px 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.task-nav-item.active {
  position: relative;
}

.task-nav-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #151D36;
}

.task-nav-fixed .task-nav {
  position: fixed;
  left: 0;
  top: 40px;
  right: 0;
  padding: 0 28px;
  background: #fff;
}

.task-nav-fixed .task-nav-holder {
  display: block;
}
</style>
